/*================================================================================
	Item Name: Rukun - Property Management System
	Version: 2.0
	Author: Jamhuriya Technology Solutions
	Author URL: https://jtech.so
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.WQNxq {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: inherit !important;
  overflow-y: inherit !important;
  /* min-height: 0; */
}

// .accName span{
//    position: absolute;
// }
// .accName span::before{
//     position: relative;
//     left: 0;
//     content: '';
//     width: ;
// }

tr{
  td {
    svg {
        vertical-align: middle;
        margin-right: 5px;
    }
  }
}
tr.expandedAccount {
  td {
    svg:not(.not-rotate) {
        transform: rotate(90deg);
    }
  }
}


.p-treeselect-panel{
  z-index: 9999 !important;
}

@media print {
  .p-treetable-toggler{
    display: none;
  }
}
